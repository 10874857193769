import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/design-tokens/dist/global/json/tokens.json';
import ColourBlock from '@components/colour-block';
import { PrimaryColours, SecondaryColours, PromotionalColours, BaseColours, ExtendedColours, MonochromeColours, SemanticColours, ColourLookup, TableCellResult } from '@components/design-tokens';
import FurtherReading from '@shared/colours/further-reading';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-palettes"
            }}>{`Colour palettes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#interaction-palettes"
            }}>{`Interaction palettes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#monochrome-palette"
            }}>{`Monochrome palette`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#semantic-palette"
            }}>{`Semantic palette`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-accessibility"
            }}>{`Colour accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "colour-palettes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-palettes",
        "aria-label": "colour palettes permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour palettes`}</h2>
    <p>{`Nectar’s selection of colours are divided into distinct palettes. The available palettes are Primary and Monochrome.`}</p>
    <p>{`Nectar’s core colour is purple, while yellow, green, and red are additional primary colours to add vibrancy and variation. The purple is the colour associated with Nectar by users, so it’s important to use this distinctive colour first and foremost.`}</p>
    <h3>{`Primary brand palette`}</h3>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#8223FA" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#6C18CB" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#F2F1F5" mdxType="ColourBlock">Light</ColourBlock>
    </GridList>
    <h3>{`Secondary brand palette`}</h3>
    <p>{`The secondary palette contains a variety of colours to accent and identify the brand. You can use the secondary palette once the user has been fully introduced to the personality of the brand. When used with the primary palette, these colours help every interaction feel on-brand and informative.`}</p>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#FF6D4F" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#D75241" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#F2F1F5" mdxType="ColourBlock">Light</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "interaction-palettes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#interaction-palettes",
        "aria-label": "interaction palettes permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Interaction palettes`}</h2>
    <p>{`The following colour palettes represent how the brand colours are used in interaction states.`}</p>
    <h3>{`Primary interaction palette`}</h3>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#8223FA" mdxType="ColourBlock">Default</ColourBlock>
  <ColourBlock fill="#6421B8" mdxType="ColourBlock">Hover</ColourBlock>
  <ColourBlock fill="#4A1D83" mdxType="ColourBlock">Pressed</ColourBlock>
  <ColourBlock fill="#D8D4E0" mdxType="ColourBlock">Disabled</ColourBlock>
    </GridList>
    <h3>{`Secondary interaction palette`}</h3>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">Default</ColourBlock>
  <ColourBlock fill="#8223FA" opacity={10} fontColour="#000" mdxType="ColourBlock">
    Hover
  </ColourBlock>
  <ColourBlock fill="#8223FA" opacity={20} fontColour="#000" mdxType="ColourBlock">
    Pressed
  </ColourBlock>
  <ColourBlock fill="#D8D4E0" mdxType="ColourBlock">Disabled</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "monochrome-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#monochrome-palette",
        "aria-label": "monochrome palette permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Monochrome palette`}</h2>
    <p>{`The monochrome palette is a group of colours that can provide text contrast and neutrality to designs.`}</p>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#000000" mdxType="ColourBlock">Black</ColourBlock>
  <ColourBlock fill="#26212F" mdxType="ColourBlock">Darker</ColourBlock>
  <ColourBlock fill="#3F384C" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#726A82" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#D8D4E0" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#F2F1F5" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">White</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "semantic-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#semantic-palette",
        "aria-label": "semantic palette permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Semantic palette`}</h2>
    <p>{`The semantic palette is a shared group of colours that help communicate key messages like errors, warnings, successes and understanding next steps. The consistent use of semantic colours keeps cognitive load low and makes for a unified and engaging user experience across our brands.`}</p>
    <h3>{`Error`}</h3>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#FFF0ED" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#FF876F" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#B20620" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#D75241" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#AF3734" mdxType="ColourBlock">Darker</ColourBlock>
    </GridList>
    <h3>{`Info`}</h3>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#E8F1FB" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#428FDA" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#1669BA" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#135CA3" mdxType="ColourBlock">Darker</ColourBlock>
    </GridList>
    <h3>{`Success`}</h3>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#E6FBF5" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#2EE0AB" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#00D999" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#00B587" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#008971" mdxType="ColourBlock">Darker</ColourBlock>
    </GridList>
    <h3>{`Warning`}</h3>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#FFFAE6" mdxType="ColourBlock">Lighter</ColourBlock>
  <ColourBlock fill="#FFD62E" mdxType="ColourBlock">Light</ColourBlock>
  <ColourBlock fill="#FFCD00" mdxType="ColourBlock">Base</ColourBlock>
  <ColourBlock fill="#DAA401" mdxType="ColourBlock">Dark</ColourBlock>
  <ColourBlock fill="#B47C02" fontColour="#000" mdxType="ColourBlock">
    Darker
  </ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "colour-accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-accessibility",
        "aria-label": "colour accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour accessibility`}</h2>
    <p>{`To comply with the Web Content Accessibility Guidelines AA standard contrast ratios, choose colours from the Nectar palette that have sufficient colour contrast between them so that people with low vision can see and use our products.`}</p>
    <h3>{`Primary palette accessibility`}</h3>
    <p>{`When using Purple Base (#8223FA) as a background colour, only use Monochrome White (#FFFFFF) or Monochrome Lighter (#F2F1F5) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    Minimal contrast ratio: 4.5
    <ColourBlock fill="#8223FA" fontColour={GlobalTokens.color.monochrome.white} center={true} hideValues={true} mdxType="ColourBlock">
      Monochrome White
    </ColourBlock>
    <ColourBlock fill="#8223FA" fontColour={GlobalTokens.color.monochrome.lighter} center={true} hideValues={true} mdxType="ColourBlock">
      Monochrome Lighter
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    Fail
    <ColourBlock fill="#8223FA" fontColour={GlobalTokens.color.monochrome.light} center={true} hideValues={true} mdxType="ColourBlock">
      Monochrome Light
    </ColourBlock>
    <ColourBlock fill="#8223FA" fontColour={GlobalTokens.color.monochrome.light} center={true} hideValues={true} mdxType="ColourBlock">
      Monochrome Light
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Red Base (#FF6D4F), Green Base (#00D999) or Yellow Base (#FFCD00) as a background colour, use the Darkest extended colour of the base colour for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    Minimal contrast ratio: 4.5
    <ColourBlock fill="#FF6D4F" center={true} fontColour="#5F0019" hideValues={true} mdxType="ColourBlock">
      Red Darkest
    </ColourBlock>
    <ColourBlock fill="#00D999" center={true} fontColour="#004851" hideValues={true} mdxType="ColourBlock">
      Green Darkest
    </ColourBlock>
    <ColourBlock fill="#FFCD00" center={true} fontColour="#6A2A05" hideValues={true} mdxType="ColourBlock">
      Yellow Darkest
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    Fail
    <ColourBlock fill="#FF6D4F" center={true} fontColour="#FFF" hideValues={true} mdxType="ColourBlock">
      Monochrome White
    </ColourBlock>
    <ColourBlock fill="#00D999" center={true} fontColour="#FFF" hideValues={true} mdxType="ColourBlock">
      Monochrome White
    </ColourBlock>
    <ColourBlock fill="#FFCD00" center={true} fontColour="#FFF" hideValues={true} mdxType="ColourBlock">
      Monochrome White
    </ColourBlock>
  </Dont>
    </Guideline>
    <hr></hr>
    <FurtherReading mdxType="FurtherReading" />
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      